html::-webkit-scrollbar {
  width: 0.1rem;
}

html::-moz-scrollbar {
  width: 0.1rem;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

html::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

html::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

html::-moz-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

body {
  font-family: 'Roboto', sans-serif;
}

a {
  color: #ff9800;
}

.ant-modal-wrap::-webkit-scrollbar {
  width: 0.1rem;
}

.ant-modal-wrap::-moz-scrollbar {
  width: 0.1rem;
}

.ant-modal-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

.ant-modal-wrap::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

.ant-modal-wrap::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

.ant-modal-wrap::-moz-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

.welcomeText h1 {
  font-size: 25px;
}
.welcomeText p {
  max-width: 50%;
  font-size: 16px;
}
.text-center {
  text-align: center;
}

.site-banner-content,
.site-business-discovery-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}
.site-business-discovery-content {
  background: rgb(248, 248, 248);
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}
[data-theme='dark'] .logo {
  background: rgba(255, 255, 255, 0.3);
}

.logo {
  margin: 16px 24px 16px 0;
  float: left;
  margin: 16px;
  background: transparent;
}

.logo-inner {
  margin: 50px auto 16px auto !important;
  float: none;
}

.f-right {
  float: right;
}
.mr-5 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 100px;
}
.h-overflow {
  overflow: hidden;
}
.pr-15 {
  padding-right: 15px !important;
}

.portal-title {
  font-weight: 700;
  padding-left: 20px;
}

.total-amount {
  text-align: center;
  padding: 60px 0;
  font-weight: 700;
  font-size: 28px;
}

.summaryHolder {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: -23px;
  margin-bottom: -23px;
  padding: 20px 0;
}
.b-right {
  border-right: 1px solid #f0f0f0;
}
.bg-placeholder {
  flex-grow: 1;
  margin-right: 20px;
}
.bg-placeholder + .ant-divider-vertical {
  height: 100%;
  bottom: 0;
  position: absolute;
  left: 50%;
}
.bg-placeholder p {
  text-align: center;
  padding: 20px 0;
}
.stats {
  margin-bottom: 31px;
}
.text-muted {
  color: #919191;
}
.payout-desc {
  text-align: center;
  padding: 30px 10px;
}

.ant-progress-line + button,
.payout-desc + button {
  margin: 10px auto 20px auto;
  display: block;
}
.payout-desc + button {
  margin-bottom: 40px;
}
.ant-progress-line {
  width: 70%;
  display: block;
  margin: 50px auto;
}

.grey-background {
  background: #f0f2f5;
  min-height: 80vh;
}

.bg-gray {
  background-color: #fbfbfb;
}

.register-area {
  max-width: 960px;
  margin: 30px auto !important;
  background: #fff;
  padding: 50px;
}

.register-area label {
  margin: 10px 0;
  display: block;
}
.mt-5 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 10px;
}
.mt-20 {
  margin-top: 30px;
}

.min-height-vh {
  min-height: 100vh;
}
.ant-dropdown-button {
  margin: 0 8px 8px 0;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  z-index: 99999;
}

.trigger:hover {
  color: #ff9800;
}

.starter-text {
  max-width: 260px;
  background: #fff;
  padding: 25px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid #efefef;
}
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active {
  border-color: transparent !important;
}
.site-layout .ant-menu-horizontal > .ant-menu-item {
  padding-left: 0;
}
.ant-menu-horizontal > .ant-menu-item button {
  padding-right: 0;
}
.ant-steps-item-container {
  margin-bottom: 20px;
}

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail,
.ant-steps-vertical .ant-steps-item-icon {
  margin-top: 15px;
}
.ant-steps-item-button {
  position: absolute;
  right: 0;
  top: 10px;
}
.ant-modal-content p {
  color: #444;
  font-size: 16px;
}
.ant-modal-content p.introText {
  max-width: 70%;
  text-align: center;
  margin: 20px auto 30px auto;
}

.ant-form-item-label label {
  font-weight: 700;
  line-height: 30px;
  font-size: 14px;
  color: #444;
}
.ant-form-item-control span.desc {
  display: block;
  line-height: 1.4;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 165, 154);
}
.mb-5 {
  margin-bottom: 5px;
}
.avatar-uploader .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin: 0 auto;
  float: none;
}
.ant-modal-title {
  text-align: center;
}
.infotext {
  font-size: 13px;
  color: rgb(164, 165, 154);
}
.d-none {
  display: none;
}

.no-data-field {
  margin-top: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.option-modal .ant-modal-footer {
  display: none;
}

.option-desc > span {
  max-width: 70%;
  float: left;
}
.option-desc > button {
  float: right;
}

.seo-uploader .ant-upload.ant-upload-select-picture-card {
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding: 15px;
}

.comment-section .ant-input {
  border: 1px dashed #d9d9d9;
}

.comment-section .ant-input:hover {
  border: 1px dashed #d9d9d9;
}

/* iframe {
  display: none !important;
} */

.page-header {
  position: relative;
  margin-bottom: 2rem;
}

.page-header .page-image {
  padding-top: 0.3rem;
}

.page-header .page-name {
  position: absolute;
  top: 0;
  left: 7rem;
}

.page-header .page-name h4:first-child {
  font-size: 0.8rem;
  color: rgb(164, 165, 154);
  font-weight: 400;
}

.page-header .page-name h4:last-child {
  font-size: 1rem;
  color: #222222;
  font-weight: 500;
}

.page-header .page-url {
  position: absolute;
  bottom: 0;
  left: 7rem;
  width: 80%;
}

.page-header .page-url input {
  padding: 0.3rem;
}

.page-header .page-url .ant-input-group-addon {
  background-color: #e7f1f2;
  color: #555;
}

.list .key {
  font-weight: 400;
  font-size: 0.8rem;
}

.list .value span:first-child {
  padding-right: 1rem;
}

.list .value {
  font-weight: 600;
  font-size: 0.8rem;
}

.list .value .ant-switch-checked {
  background-color: #3bb75e;
}

.page-buttons {
  text-align: center;
  margin-top: 1.5rem;
}

.page-buttons button {
  margin-right: 0.5rem;
}

.pay-details {
  position: relative;
  text-align: center;
}

.pay-details h2 {
  margin-top: 1rem;
  font-weight: 400;
  color: #424242;
}

.pay-details h4 {
  font-weight: 500;
  color: #bdbdbd;
}

.pay-details p {
  font-size: 0.8rem;
  color: #757575;
}

.pay-form-col {
  background: #fbfbfb;
  border: 0.0625rem solid #f3f3f3;
  padding: 1rem;
  display: block;
  /* -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%; */
  margin-top: 2rem;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.pay-form-col .ant-form-item-label label {
  font-weight: 500;
  line-height: 25px;
  font-size: 12px;
  color: #444;
}

.pay-form-col .hide-label label {
  visibility: hidden;
}

.pay-logos img {
  padding-right: 2rem;
}

.spinner {
  text-align: center;
  border-radius: 4px;
  margin-top: 50px;
  padding: 50px 50px;
  margin: 20px 0;
}

#transactions-bar {
  min-width: 320px;
  max-width: 100%;
}
#successrate-donut,
#failurerate-donut {
  max-height: 100px;
}
text.highcharts-credits {
  display: none;
}

.btn-input {
  margin-top: 1rem;
}

.login-btn {
  background: #f55c29;
  border: 0.0625rem solid #f55c29;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.login-btn:hover,
.login-btn:focus {
  background: #fc602c;
  border: 0.0625rem solid #fc602c;
}

.login-header {
  color: #607d8b;
  font-size: 1rem;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.login-row-first {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3.375rem;
  text-align: center;
}

.login-row-second {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.375rem;
  text-align: center;
}

.login-row-second h4 {
  font-size: 1.3rem;
  font-weight: 300;
  color: #607d8b;
}

.login-row-third {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.375rem;
}

.login-row-third h4 {
  font-size: 0.8rem;
  color: #607d8b;
}

.login-row-fourth {
  display: block;
  text-align: center;
  margin-top: 5.375rem;
}

.login-row-fourth h4:first-child {
  font-size: 0.8rem;
  color: #607d8b;
  font-weight: 400;
}

.login-row-fourth span {
  font-size: 0.8rem;
  padding-right: 0.95rem;
}

.login-row-fourth span a {
  color: #607d8b;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.login-row-fourth span a:hover {
  color: #455a64;
}

.pay-form-col {
  background: #ffffff;
  padding: 1.3rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 15px 0 rgba(117, 117, 117, 0.25);
  border: 0.0625rem solid #fbfbfb;
}

.login-forgot {
  margin-top: 0.8rem;
}

.login-forgot a {
  font-size: 0.8rem;
  font-weight: 500;
}

.language {
  position: absolute;
  right: 3rem;
  top: 1rem;
}

.language i {
  margin-right: 1rem;
  cursor: pointer;
}

.ant-menu li.lang-item {
  opacity: 0.4;
  margin-left: 0.2rem !important;
}

.ant-menu li.lang-item.active {
  opacity: 1;
}

.column-text {
  font-size: 0.8rem;
}

thead[class*='ant-table-thead'] th {
  color: #222;
  font-weight: 700;
}

.export-button {
  background-color: #fb8c00;
  color: #fff;
  border: 0.0625rem solid #fb8c00;
}

.export-button:hover {
  background-color: #f57c00;
  color: #fff;
  border: 0.0625rem solid #f57c00;
}

.transparent-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.transparent-button:focus,
.transparent-button:active {
  background: transparent;
  border: none;
  outline: none;
}

.highcharts-container {
  position: relative;
  overflow-x: auto !important;
  overflow-y: hidden;
  width: 100% !important;
  height: 400px;
  text-align: left;
  line-height: normal;
  z-index: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

.highcharts-container::-webkit-scrollbar {
  width: 0.1rem;
}

.highcharts-container::-moz-scrollbar {
  width: 0.1rem;
}

.card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 1px solid #b3e5fc;
  -webkit-border-radius: 10px;
  border-radius: 5px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.ratio-box .ratio-title {
  font-weight: 500;
  margin-top: 10px;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffa726;
}

.ratio-box .ratio-point {
  margin-top: 12px;
  text-transform: uppercase;
  color: rgba(66, 84, 102, 0.4) !important;
  font-size: 0.8rem;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/* @media screen and (min-width: 768px) { */
/** SIDE BAR BEGIN **/
.ant-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  background: #ffffff;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #f9f9f9;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  /* padding: 10px; */
}

.ant-menu-item-group-title {
  color: #0090fe !important;
  font-size: 0.85rem;
  font-weight: 600;
  margin-left: 8px;
  letter-spacing: 2px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f9f9f9;
}

.ant-menu-light {
  background: #f9f9f9;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f9f9f9;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  position: absolute;
  min-width: 14px;
  margin-left: 0;
  font-size: 14px;
  right: 4px;
  top: 30%;
  -webkit-transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .ant-menu-item-icon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  position: absolute;
  left: 40%;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 30px !important;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  font-size: 0.85rem;
  color: #425466 !important;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
  color: #425466 !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
  font-size: 0.85rem;
}

.ant-menu-submenu-selected {
  color: #425466 !important;
  background-color: #d8e6eb;
  /* border-radius: 5px; */
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f27649;
  color: #ffffff !important;
  /* border-radius: 2px; */
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 0 !important;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}

.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  height: 48px;
  padding: 0 !important;
  color: #425466;
  line-height: 48px;
  text-align: center;
  background: #f2f4f8;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.menu-info {
  padding: 10px;
  background-color: #eff2f6;
  border-radius: 2px;
  width: 180px;
  margin-top: 100px !important;
  margin-left: 10px !important;
}

.menu-info p {
  color: #7275a7;
  font-size: 0.8rem;
}

.menu-info-btn {
  width: 100%;
  background-color: #0090fe;
  border: 1px solid #0090fe;
  border-radius: 20px;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #ffffff;
}

/** END SIDE BAR **/

/** TOP BAR BEGINS **/
.site-layout .site-layout-background {
  background: #fff !important;
}

.ant-layout-header {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;
  line-height: 64px;
}

.site-box {
  padding-left: 15px !important;
  padding-right: 15px !important;
  min-height: 80vh;
  padding-top: 20px;
}

.margin-top-big {
  margin-top: 30px;
}

.top-nav-first {
  padding: 10px;
  top: 2px;
}

.topNavIcons {
  position: absolute;
  left: 20px;
}

.topNavIcons svg {
  padding: 5px;
}

.top-nav-icons {
  color: #0090fe;
}

.lang-buton {
  border: none;
  box-shadow: none;
}

.lang-buton .grey-bg {
  background-color: #eff2f6;
  padding: 8px;
  border-radius: 3px;
}

.topNavHeaders {
  position: absolute;
  top: 3px;
  left: 70px;
}

.topNavHeaders h4 {
  color: #0090fe;
}

.topNavHeaderSub {
  position: absolute;
  top: 3px;
  left: 230px;
}

.topNavHeaderSub h4 {
  color: #f27649;
}

.header-box {
  position: relative;
}

.user-box {
  position: absolute;
  right: 0;
  top: 0;
}
/** TOP BAR ENDS **/

/** FOOTER BEGINS **/
.ant-layout-footer {
  padding-top: 2px !important;
  padding-left: 0 !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #ffffff;
  max-height: 50px;
  text-align: left;
}

.footer-padding {
  padding: 0px 30px 0px 30px;
  position: relative;
  bottom: 0;
}

.ant-layout-footer h4 {
  font-weight: 300;
}

.ant-layout-footer p {
  font-size: 0.8rem;
}

/** FOOTER ENDS **/

/** CONTENT **/

.padding-box {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.upper-header {
  position: relative;
  margin-top: 5px;
}

.upper-header h4 {
  position: absolute;
  padding: 0px;
  left: 0;
  font-weight: 400;
  font-size: 1rem;
}

.upper-header h6 {
  position: absolute;
  padding: 0px;
  right: 0;
  font-weight: 300;
  font-size: 1rem;
  color: #0090fe;
}

.margin-top {
  position: relative;
  margin-top: 50px;
}

.ant-card {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.09) !important;
  margin-bottom: 25px !important;
  border: 1px solid #84d4ef;
}

.ant-card .ant-card-head {
  border-color: #f1f2f6;
}

.ant-card .ant-card-head .ant-card-head-title {
  padding: 18px 0;
}

.ant-card .ant-card-head-title .ant-page-header-heading-title {
  font-weight: 400;
}

.ant-card .ant-card-body {
  padding: 0;
}
.stats-padding {
  padding: 20px 0px 0px 20px !important;
}

.ecard {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.ecard .card-chunk {
  width: 50%;
  flex: 0 0 50%;
}

.ecard .card-spinner {
  width: 100%;
  flex: 0 0 100%;
  text-align: center;
  padding: 25px;
}

.profit-card-spinner {
  width: 100%;
  flex: 0 0 100%;
  text-align: center;
  padding: 25px;
}

.ecard .card-chunk h1 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 4px;
  color: #141322 !important;
}

.ecard .card-chunk p {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
}

.ecard .card-chunk-raw {
  width: 100%;
  flex: 0 0 100%;
}

.ecard .card-chunk-raw h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 4px;
  color: #141322 !important;
}

.ecard .card-chunk-raw .value {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.7rem;
  font-family: 'Mukta', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.ecard .card-chunk-raw .padding {
  padding-bottom: 10px;
}

.ecard .card-chunk-raw .desc {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: -10px;
}

.transaction-chart-text {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
}

.currency .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none !important;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: -5px;
}

.currency .ant-select-focused .ant-select-selector,
.currency .ant-select-selector:focus,
.currency .ant-select-selector:active,
.currency .ant-select-open .ant-select-selector {
  border-color: 0 !important;
  box-shadow: none !important;
}

.currency .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.currency
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-right: 18px;
  margin-top: 3px;
}

.chart-padding {
  padding: 20px;
}

.table-padding {
  padding: 10px;
}

.profit-card {
  position: relative;
  padding: 20px;
}

.profit-card h4 {
  text-transform: uppercase;
  font-size: 1rem;
}

.profit-card p {
  font-size: 0.8rem;
}

.profit-card h1 {
  font-size: 1.2rem;
}

.filterButton {
  border-radius: 20px;
  background-color: #f55c29;
  border: 1px solid #f55c29;
  transition: all 0.5s ease-in-out;
}

.filterButton:hover {
  background-color: rgba(245, 92, 41, 0.7);
  border: 1px solid rgba(245, 92, 41, 0.7);
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 15px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #f55c29;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.resetButton {
  border-radius: 20px;
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  transition: all 0.5s ease-in-out;
}

.ant-collapse {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: transparent;
  border: 0;
  border-bottom: 0;
  border-radius: 0;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}

.ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 0;
  padding-left: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 6px;
  left: 60px;
  font-size: 12px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  padding-top: 16px;
  padding-bottom: 10px;
}

.ant-collapse-content {
  border-top: 0;
}

.margin-top-small {
  margin-top: 15px;
}

.utility-buttons {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.utility-buttons .export-buttons:first-child {
  margin-right: 20px;
}

.utility-buttons .export-buttons:last-child {
  margin-left: 20px;
  background-color: #f55c29;
  border: 1px solid #f55c29;
  text-transform: uppercase;
  border-radius: 20px;
}

.refresh-btn {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
}

.refresh-btn:hover,
.refresh-btn:active,
.refresh-btn:focus {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
}

.export-buttons-excel {
  margin-left: 20px;
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  border-radius: 20px;
}

.new-payout .ant-btn-primary[disabled],
.new-payout .ant-btn-primary[disabled]:hover,
.new-payout .ant-btn-primary[disabled]:focus,
.new-payout .ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #81cce6;
  border: 1px solid #81cce6;
  text-shadow: none;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.new-page-btn {
  background-color: #f55c29;
  border: 1px solid #f55c29;
  text-transform: uppercase;
  border-radius: 20px;
}

.new-page-btn:hover {
  background-color: #f55c29;
  border: 1px solid #f55c29;
}

.new-cancel-btn {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
}

.new-cancel-btn:hover {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  color: #fff;
}

.utility-buttons .export-buttons {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  border-radius: 20px;
}

.utility-buttons .reload {
  margin-right: 20px !important;
}

.utility-buttons .btwocaction {
  margin-right: 20 !important;
}

.table-tag-channel {
  background-color: transparent;
  border-radius: 10px;
}

.table-tag-merchant {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #76cca5;
  color: #76cca5;
  text-align: center;
  font-weight: 700;
}

.ant-table-thead {
  text-transform: uppercase;
}

.ant-table-row {
  cursor: pointer;
}

.trans-detail-header-box {
  position: relative;
  border: 1px solid #eef0f5;
  padding: 20px;
  margin-top: 20px;
}

.trans-detail-header-box button:first-child {
  background-color: #35b9e6;
  color: #fff;
  border: 1px solid #35b9e6;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 300;
}

.trans-detail-header-box button:last-child {
  float: right;
  background-color: #d9dee9;
  color: #1c1c2b;
  border: 1px solid #d9dee9;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 300;
}

.new-record-box {
  position: relative;
  border: 1px solid #eef0f5;
  padding: 5px 15px 5px 15px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.new-record-box .amount-details {
  padding: 5px;
}

.new-record-box .amount-details h4 {
  font-size: 13px;
  color: #999bbf;
}

.new-record-box .amount-details h2 {
  font-size: 30px;
  color: #63669d;
  font-weight: 300;
}

.new-record-box .payout-button-box {
  position: absolute;
  right: 15px;
  top: 40px;
}

.new-record-box .payout-button-box button {
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 20px;
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  font-weight: 300;
}

.new-record-box .payout-button-box button:hover {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
}

.merchant-box {
  margin-top: 10px;
  border: 1px solid #35b9e6;
  padding: 5px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.09) !important;
  border-radius: 5px;
  position: relative;
}

.merchant-box .avatar {
  padding: 10px;
}

.merchant-box .ant-avatar {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid #8489b7;
}

.merchant-box .ant-avatar .ant-avatar-string {
  top: 15%;
  font-size: 14px !important;
}

.merchant-box .merchant-amount {
  position: absolute;
  right: 20px;
  top: 20px;
}

.merchant-box .merchant-amount h2 {
  font-size: 30px;
  font-weight: 300;
}

.merchant-box .footer-box {
  position: relative;
  padding: 7px;
}

.merchant-box .footer-box .footer {
  position: absolute;
  right: 10px;
  bottom: -1px;
}

.merchant-box .footer-box .footer h4 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ababc8;
  font-weight: 300;
}

.trans-detail-info {
  position: relative;
  padding: 20px 0 20px 0;
  margin-top: 10px;
}

.trans-detail-info .trans-amount {
  position: absolute;
  left: 0;
  top: 0;
}

.trans-detail-info .trans-amount h4 {
  font-size: 0.7rem;
  color: #abadca;
}

.trans-detail-info .trans-amount h2 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #595c97;
  padding: 0;
}

.trans-detail-info .trans-status {
  position: absolute;
  right: 0;
  top: 20px;
}

.trans-detail-info .trans-status button {
  border: none;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 0;
  font-weight: 300;
}

.trans-status-success {
  background-color: #41b883;
  color: #fff;
}

.trans-status-success:hover {
  background-color: #41b883;
  color: #fff;
}

.trans-status-canceled {
  background-color: #d9dee9;
  color: #595c97;
}

.trans-status-canceled:hover {
  background-color: #d9dee9;
  color: #595c97;
}

.trans-status-declined {
  background-color: #b71c1c;
  color: #fff;
}

.trans-status-declined:hover {
  background-color: #b71c1c;
  color: #fff;
}

.trans-detail {
  margin-top: 50px;
}

.trans-detail .list .key {
  font-size: 0.9rem;
  color: #abadca;
}

.trans-detail .list .value {
  font-size: 0.9rem;
  color: #595c97;
  font-weight: 400;
}

.trans-detail-footer {
  position: relative;
  border: 1px solid #eef0f5;
  padding: 20px;
}

.trans-detail-footer .customer-name {
  position: absolute;
  left: 60px;
  top: 8px;
}
.trans-detail-footer .customer-name h4 {
  margin-top: 10px;
  margin-bottom: 0;
}

.trans-detail-footer .customer-name h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 0.7rem;
  color: #abadca;
}

.blacklist-customer {
  position: relative;
  padding: 10px 0 10px 0;
}

.blacklist-customer h4 {
  float: right;
  color: #595c97;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
}

.settings-page {
  background: #f9f9f9;
  height: 100%;
  padding: 20px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f55c29;
  font-weight: 500;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #f55c29;
  pointer-events: none;
}

.ant-tabs-tab .anticon {
  margin-right: 0px;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  background: #fff;
  padding: 20px;
}

.profile-box {
  background: #fff;
  margin-top: 10px;
  padding: 10px 50px 10px 50px;
  text-align: center;
  border: 1px solid #fafafa;
}

.profile-box h3 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
}

.profile-box .form-label {
  text-align: right;
  margin-top: 5px;
}

.profile-box .change-password-btn {
  text-align: left;
}

.profile-box .change-password-btn button {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
  text-transform: uppercase;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 300;
}

.profile-box .switch-btn {
  text-align: left;
}

.profile-box .switch-btn .ant-switch-checked {
  background-color: #41b883;
}

.profile-box .tech-skill {
  text-align: left;
}

.profile-box .form-submit-btn {
  margin-top: 30px;
}

.profile-box .form-submit-btn button {
  border-radius: 20px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #f55c29;
  border: 1px solid #f55c29;
}

.ant-form-item-explain.ant-form-item-explain-error {
  text-align: left;
}

.change-password-button {
  border-radius: 20px;
}

.change-password-button:last-child {
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
}

.empty-box-button {
  border-radius: 20px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #35b9e6;
  border: 1px solid #35b9e6;
}

.suspense-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tranaction-table .ant-table-tbody > tr > td,
.tranaction-table .ant-table tfoot > tr > th,
.tranaction-table .ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 16px;
  overflow-wrap: break-word;
}

.ant-table-body::-webkit-scrollbar {
  width: 0.3rem;
}

.ant-table-body::-moz-scrollbar {
  width: 0.1rem;
}

.ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

.ant-table-body::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background-color: #fff;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

.ant-table-body::-moz-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.5);
}

.pces-share .ecard .card-chunk h1 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 4px;
  color: #f55c29 !important;
}

.pces-share .ecard .card-chunk p {
  color: #f55c29;
  font-size: 0.7rem;
  font-family: 'Mukta', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.table-padding .ant-table {
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.09) !important;
  margin-bottom: 25px !important;
  border: 1px solid #84d4ef;
  /* margin: 0px -10px 0px -10px; */
}

.filter-form .ant-form-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  margin-bottom: 0;
  vertical-align: top;
}

.form-container {
  max-width: 600px;
  width: 100%;
  margin: 0px 0 20px;
}

.form-container .ant-form-item {
  margin-bottom: 10px;
}

.form-container
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input,
.ant-select-selector {
  /* height: 50px; */
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 500;
}

.form-container
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input::placeholder {
  color: #8c90b5;
}

.form-container
  .ant-form-item
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input,
.ant-select-selector .ant-select-selection-placeholder {
  line-height: 50px;
}

.form-container button {
  border-radius: 6px;
  height: 54px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  margin-top: 15px;
  background-color: #0090fe;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 7px;
  left: 75px;
  font-size: 12px;
}
.selectable-item {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 16.8px;
  box-shadow: 0 0 4px rgb(0 0 0 / 10%);
  margin: 0 auto 20px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.selectable-item.selected,
.selectable-item:hover {
  border-color: #ff4500;
  background: #fff;
  box-shadow: 0 0 7px rgb(255 118 0 / 68%);
}
.selectable-item h2 {
  margin-top: 0.7rem;
  font-size: 0.8rem;
  color: #949dac;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.selectable-item.selected h2,
.selectable-item:hover h2 {
  color: #ff4500;
}

/* * BULK PAYMENT */
body {
  overflow-x: hidden;
  color: #232b2b;
  font-weight: 400;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

p,
a {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}
.wireframe {
  position: absolute;
  transform: rotate(45deg) translate(-52%, -4%);
  width: 1800px;
  z-index: -1;
}
.wizard-card ul.nav .search-nav input.form-control {
  border-color: #425466;
  height: 16px;
  font-size: 16px;
  border-radius: 16.5px;
  padding: 20px;
}

.wizard-card ul.nav.wizard-nav li {
  display: flex;
  width: 100%;
}

.wizard-card ul.nav.wizard-nav li:not(.search-nav) {
  margin-right: -39px;
  padding: 10px 25px;
  border: 1px dashed #fff;
  border-right: 0 none;
  border-radius: 10px;
}

.wizard-card ul.nav.wizard-nav li.search-nav {
  margin-bottom: 17px;
}

.wizard-card ul.nav.wizard-nav li a {
  text-decoration: none;
  color: #425466;
}

.wizard-card ul.nav.wizard-nav li.active {
  background-color: #f55c29;
  border-color: #ffdbce;
}

.wizard-card ul.nav.wizard-nav li.active a {
  color: #fff;
}

.wizard-card .nav.wizard-nav {
  margin: 24px 0;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/*** WIZARD ******/

.payment-container {
  margin-top: 1rem;
  padding: 1rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.payment-container img {
  height: 4rem;
  margin-top: 1.5rem;
}

.payment-container i {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

/*-- Step Wizard --*/

.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #595959;
  font-size: 16px;
  margin-bottom: 5px;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
  text-transform: none;
}
.bs-wizard > .bs-wizard-step.complete .bs-wizard-info {
  color: #f55c29;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  top: 15px;
  left: 50%;
  margin-top: -10px;
  margin-left: -15px;
  border-radius: 50%;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: ' ';
  width: 14px;
  height: 14px;
  background: #f55c29;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 0.125rem;
  box-shadow: none;
  margin: 20px 0 8px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #ff8055;
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 1;
  background-color: #f5f5f5;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

/**-- End Step Wizard --**/

@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

progress {
  vertical-align: baseline;
}

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .payment-app .container,
  .payment-app .container-sm,
  .payment-app .container-md,
  .payment-app .container-lg,
  .payment-app .container-xl {
    max-width: 1500px;
  }
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #858796;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e3e6f0;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}

.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e3e6f0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #858796;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cdd8f6;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a3b6ee;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #858796;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4e73df;
  transition: width 0.6s ease;
}

.active .progress-bar {
  transition: width 0.6s ease 0.5s;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.organe-text-color {
  color: #ff9800;
}